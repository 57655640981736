const swalSMSSuccess = {
  title: 'Compra realizada com sucesso!',
  text: 'O seu número já está disponível em "Números ativos"!',
  icon: 'success',
  showCancelButton: true,
  confirmButtonText: 'Ir para números ativos',
  cancelButtonText: 'Fechar',
  customClass: {
    confirmButton: 'btn btn-primary',
    cancelButton: 'btn btn-danger ml-1'
  },
  buttonsStyling: false
}

const swalSMSNoNumbers = {
  title: 'Sem números disponíveis no momento!',
  icon: 'warning',
  customClass: {
    confirmButton: 'btn btn-primary'
  },
  buttonsStyling: false
}

const swalSimpleError = {
  title: 'Ops! Algo de errado não está certo!',
  text: 'Parece que algo deu errado, tente novamente mais tarde.',
  icon: 'error',
  customClass: {
    confirmButton: 'btn btn-primary'
  },
  buttonsStyling: false
}

const swalOrderUniqueSuccess = {
  title: 'Compra realizada com sucesso!',
  text: 'Seu Pedido foi aprovado e já está disponível em "Meus Seguidores"',
  icon: 'success',
  showCancelButton: true,
  confirmButtonText: 'Ir para Meus Seguidores',
  cancelButtonText: 'Fechar',
  customClass: {
    confirmButton: 'btn btn-primary',
    cancelButton: 'btn btn-danger ml-1'
  },
  buttonsStyling: false
}

const swalOrderBulkSuccess = {
  title: 'Compra realizada com sucesso!',
  text: 'Seu Pedido foi adicionado a fila e logo começara a ser processado!',
  icon: 'success',
  showCancelButton: true,
  confirmButtonText: 'Ir para Meus Seguidores',
  cancelButtonText: 'Fechar',
  customClass: {
    confirmButton: 'btn btn-primary',
    cancelButton: 'btn btn-danger ml-1'
  },
  buttonsStyling: false
}

// eslint-disable-next-line no-unused-vars
const swalOrderBulkInvalid = {
  title: 'Seu Pedido em massa está incorreto!',
  text: 'Parece que tem algo de errado no seu Pedido em Massa, por favor verifique se colocou todas as informações corretamente!',
  icon: 'error',
  showCancelButton: false,
  confirmButtonText: 'OK! Entendi!',
  customClass: {
    confirmButton: 'btn btn-primary'
  },
  buttonsStyling: false
}

const swalOrderUniqueLinkDuplicated = {
  title: 'Ops! Algo de errado não está certo!',
  text: 'Você tem um pedido ativo com este link de perfil. Aguarde até que o pedido seja concluído.',
  icon: 'error',
  showCancelButton: false,
  confirmButtonText: 'OK! Entendi!',
  customClass: {
    confirmButton: 'btn btn-primary'
  },
  buttonsStyling: false
}

const swalOrderBulkInvalidLine = (lineNumber, line, error) => {
  return {
    title: 'Seu Pedido em massa está incorreto!',
    html: `A baixo você poderá visualizar qual a linha que está com erro: <strong> <br /> <br /> Linha: ${lineNumber} <br /> <br /> Conteúdo: ${line} <br /> <br /> Erro: ${error} </strong>`,
    icon: 'error',
    width: 600,
    showCancelButton: false,
    confirmButtonText: 'OK! Entendi!',
    customClass: {
      confirmButton: 'btn btn-primary'
    },
    buttonsStyling: false
  }
}

const swalSNAccountsSuccess = {
  title: 'Compra realizada com sucesso!',
  text: 'Seu Pedido foi aprovado e já está disponível em "Minhas Contas"',
  icon: 'success',
  showCancelButton: true,
  confirmButtonText: 'Ir para Minhas Contas',
  cancelButtonText: 'Fechar',
  customClass: {
    confirmButton: 'btn btn-primary',
    cancelButton: 'btn btn-danger ml-1'
  },
  buttonsStyling: false
}

const swalSNAccountsQuantityIsInvalid = number => {
  return {
    title: 'Quantidade não disponível!',
    text: `A quantidade específicada não está disponível, temos no momento um total de ${number} contas disponíveis para a compra. Por favor selecione uma quantidade entre 1 a ${number}.`,
    icon: 'error',
    customClass: {
      confirmButton: 'btn btn-primary'
    },
    buttonsStyling: false
  }
}

const swalPackOfPhotoQuantityIsInvalid = number => {
  return {
    title: 'Quantidade não disponível!',
    text: `A quantidade específicada não está disponível, temos no momento um total de ${number} pack de fotos disponíveis para a compra. Por favor selecione uma quantidade entre 1 a ${number}.`,
    icon: 'error',
    customClass: {
      confirmButton: 'btn btn-primary'
    },
    buttonsStyling: false
  }
}

const swalVPSSuccess = {
  title: 'Compra realizada com sucesso!',
  text: 'Seu Pedido foi aprovado e já está disponível em "Minhas VPS"',
  icon: 'success',
  showCancelButton: true,
  confirmButtonText: 'Ir para Minhas VPS',
  cancelButtonText: 'Fechar',
  customClass: {
    confirmButton: 'btn btn-primary',
    cancelButton: 'btn btn-danger ml-1'
  },
  buttonsStyling: false
}

const swalPackOfPhotoSuccess = {
  title: 'Compra realizada com sucesso!',
  text: 'Seu Pedido foi aprovado e já está disponível em "Minhas Fotos"',
  icon: 'success',
  showCancelButton: true,
  confirmButtonText: 'Ir para Minhas Fotos',
  cancelButtonText: 'Fechar',
  customClass: {
    confirmButton: 'btn btn-primary',
    cancelButton: 'btn btn-danger ml-1'
  },
  buttonsStyling: false
}

const swalNoBalance = {
  title: 'Saldo insuficiente!',
  text: 'Você não tem o saldo necessário para fazer essa compra!',
  icon: 'error',
  showCancelButton: true,
  confirmButtonText: 'Adicionar Saldo',
  cancelButtonText: 'Fechar',
  customClass: {
    confirmButton: 'btn btn-warning',
    cancelButton: 'btn btn-primary ml-1'
  },
  buttonsStyling: false
}

export default {
  sms_success: swalSMSSuccess,
  no_balance: swalNoBalance,
  order_unique_success: swalOrderUniqueSuccess,
  order_bulk_success: swalOrderBulkSuccess,
  order_bulk_invalid: swalOrderBulkInvalid,
  order_unique_link_duplicated: swalOrderUniqueLinkDuplicated,
  order_bulk_invalid_line: (lineNumber, line, error) => swalOrderBulkInvalidLine(lineNumber, line, error),
  swal_sn_accounts_success: swalSNAccountsSuccess,
  swal_sn_accounts_quantity_is_invalid: number => swalSNAccountsQuantityIsInvalid(number),
  vps_success: swalVPSSuccess,
  swal_pack_of_photo_success: swalPackOfPhotoSuccess,
  swal_pack_of_photo_quantity_is_invalid: number => swalPackOfPhotoQuantityIsInvalid(number),
  swal_sms_no_numbers: swalSMSNoNumbers,
  swal_simple_error: swalSimpleError
}
